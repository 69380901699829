let resumeData = {

  "imagebaseurl": "https://github.com/musawilliam",
  "company": "  360 Creative",
  "name": "William M Rashopola",
  "role": "Frontend Developer",
  "linkedinId": "Form",
  "skypeid": "Your skypeid",
  "roleDescription": " I enjoy experimenting in many aspects of frontend development, learning about new technologies, and building programs to put my knowledge to the test.",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/in/william-rashopola-4189a994/",
      "className": "fa fa-linkedin"
    },
    {
      "name": "github",
      "url": "https://github.com/musawilliam",
      "className": "fa fa-github"
    },
    {
      "name": "skype",
      "url": "https://twitter.com/dj_ethics",
      "className": "fa fa-twitter"
    }
  ],

  "aboutme": "I am a bright, skilled, and ambitious IT Support Technician with Web Development abilities, a strong technical background, and the ability to work with minimal supervision. Exposure to a wide range of technologies, as well as the ability to diagnose hardware and software problems and ensure that quality solutions match important business objectives. Being able to arrange and articulate complex solutions clearly and accurately, as well as having a good team spirit and being deadline-oriented.",
  "address": "Sandton, Johannesburg, South-Africa",
  "website": "info@360Creative.co.za",
  "contact": "0747572146",
  "education": [
    {
      "UniversityName": "Hoerskool LangenHoven",
      "specialization": "Matriculation Year",
      "MonthOfPassing": "Jan",
      "YearOfPassing": "2010",
      "Achievements": "English Home Language, Afrikaans First Additional, Mathematical Literacy, Computer Applications Technology, Engineering Graphics and Design"
    },
    {
      "UniversityName": "W&R SETA",
      "specialization": "National Certificate: Wholesale Retail Operations",
      "MonthOfPassing": "Dec",
      "YearOfPassing": "2013",
      "Achievements": " Customer Relation and Retail Operation Certificate"
    },
    {
      "UniversityName": "IQ ACADEMY",
      "specialization": "Information Systems Technology",
      "MonthOfPassing": "Jan",
      "YearOfPassing": "2018",
      "Achievements": "Introduction to Information Systems, Information Technology, Business Information Systems, Administration, Systems Development, Information System Security, A+ and N+ (Essentials and Practical Applications), MCSE 2012 (Installing and Configuring Windows server 2012)"
    },
    {
      "UniversityName": "Google Skillshop",
      "specialization": "Google Ads Search Certification, Google Ads Display Certification ",
      "MonthOfPassing": "Dec",
      "YearOfPassing": "2021",
      "Achievements": "Fundamentals of building, Customizing effective Google Search Campaigns"
    },
  ],
  "work": [
    {
      "CompanyName": "HeyCarter (Renault)",
      "specialization": "IT Technician",
      "MonthOfLeaving": "Jan",
      "YearOfLeaving": "2019 (Current)",
      "Achievements": "I am currently an IT Technician, and my role is to manage and control the activities responsible for various technical operations involving hardware, software, and networking environments. The major goal is to limit downtime while keeping the company running efficiently."
    }
  ],
  "skillsDescription": "Your skills here",
  "skills": [
    {
      "skillname": "HTML5"
    },
    {
      "skillname": "CSS"
    },
    {
      "skillname": "Reactjs"
    }
  ],
  "portfolio": [
    {
      "name": "project1",
      "description": "mobileapp",
      "imgurl": "images/portfolio/coffee.jpg"
    },
    {
      "name": "project2",
      "description": "mobileapp",
      "imgurl": "images/portfolio/into-the-light.jpg"
    },
    {
      "name": "project3",
      "description": "mobileapp",
      "imgurl": "images/portfolio/retrocam.jpg"
    },
    {
      "name": "project4",
      "description": "mobileapp",
      "imgurl": "images/portfolio/girl.jpg"
    }
  ],
  "testimonials": [
    {
      "id": 1,
      "description": "This is a sample testimonial",
      "name": "Some technical guy"
    },
    {
      "id": 2,
      "description": "This is a sample testimonial",
      "name": "Some technical guy"
    }

  ]
}

export default resumeData