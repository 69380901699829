import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
              Feel free to contact me for any work or suggestions below:
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4> Call and WhatsApp:&nbsp;
                  <a href={`tel:${resumeData.contact}`}>{resumeData.contact}</a>
                </h4>
              </div>
            </aside>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.4989824172035!2d28.07422841565716!3d-26.11526876670737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950d2ff075d36f%3A0xb8c1c33898f6f194!2s3%20Carlo%20Rd%2C%20Bramley%20Park%2C%20Sandton%2C%202090!5e0!3m2!1sen!2sza!4v1650794960345!5m2!1sen!2sza" title="map" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114694.70319751375!2d27.98759757612142!3d-26.05940965539002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e957398cbf40517%3A0xdf8bd21bf1eb74c!2sSandton!5e0!3m2!1sen!2sza!4v1650827064392!5m2!1sen!2sza" title="map" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </section>
        );
  }
}